<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Categories ({{categories.length}})
                <span class="pb-5">
                  <button v-if="profile.role !== 'user'" class="btn btn-success btn-sm" @click="categoryModal = true; categoryMode = 'add'">+ Add Category</button>
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12">
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
            <div v-else class="row">
              <div v-for="category in categories" :key="category.id" class="col-xxl-2 col-xl-3 col-md-4 mb-4">
                <Card>
                  <div class="text-center"
                       data-bs-toggle="tooltip"
                       data-bs-placement="bottom"
                       data-bs-html="true"
                       :title="category.description">
                    <h6 class="text-dark fw-bold h5">{{category.name}}</h6>
                    <img class="img-thumbnail bg-dark-subtle" style="height: 200px; width: 100%" :src="categoryImage(category.avatar)" alt="category">
                  </div>
                  <div class="text-center">
                    <button
                        class="btn btn-outline-info btn-sm text-uppercase mt-2 w-100"
                        @click="categoryModal = true; categoryMode = 'edit'; populateCategoryEdit(category)"
                    >
                      Edit
                    </button>
                    <button
                        class="btn btn-outline-success btn-sm text-uppercase mt-2 w-100"
                        @click="$router.push('/category/' + category.id + '/products')"
                    >
                      Products
                    </button>
                  </div>
                </Card>
              </div>
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Category Modal -->
    <Modal :open="categoryModal" @closed="categoryModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="categoryMode === 'add'">Add Category</h5>
        <h5 class="modal-title m-0" v-else>Edit Category</h5>
        <button @click="categoryModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <input
                ref="refInputEl"
                type="file"
                name="avatar"
                accept=".jpeg,.png,.jpg"
                hidden
                @input="displayImage"
            >
            <div class="row col-md-4">
              <div class="form-group">
                <img class="img-thumbnail bg-dark-subtle" width="220" ref="categoryImage" :src="categoryImage(category.avatar)" alt="category">
                <br>
                <button
                    v-if="profile.role !== 'user' && profile.role !== 'admin'"
                    class="btn btn-primary mt-3 text-uppercase w-100"
                    @click="$refs.refInputEl.click()">Upload Image
                </button>
                <br>
                <span class="text-body-1 text-black-50 fst-italic mt-2">
                  jpeg, jpg, png. 2MB
                </span>
              </div>
            </div>
            <div class="row offset-md-1 col-md-7">
              <div class="form-group">
                <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
                <input type="text" class="form-control form-control-lg" placeholder="Name" v-model="category.name">
              </div>
              <div class="form-group">
                <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                <input type="text" class="form-control form-control-lg" placeholder="Description" v-model="category.description">
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="categoryModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="categoryMode === 'add'" @click="addCategory">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateCategory(category.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Category Modal End -->

  </div>
</template>

<script>
import categoryService from "@/api/categoryService";
import formatDate from "../../utils/formatDate";
import {decrypt} from "@/utils/crypto";

export default {
  name: "Categories",

  data(){
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    return {
      profile: {},
      loading: true,
      categories: [],
      categoryMode: 'add',
      categoryModal: false,
      category: {
        organization_id: this.organization.id,
        avatar: null,
        name: '',
        description: '',
      },
      organization: {},
      selectedFile: null,
      imageUrl: process.env.VUE_APP_API_IMAGE_URL + 'category/',
    }
  },

  methods: {
    formatDate,
    getCategories: async function () {
      this.categories = await categoryService.getCategories();
      this.loading = false
    },
    populateCategoryEdit(category) {
      this.category = category
    },
    displayImage: function(file) {
      const { files } = file.target
      if (files && files.length) {
        this.selectedFile = files[0]
        this.$refs.categoryImage.src = URL.createObjectURL(files[0])
      }
    },
    async addCategory() {
      if (this.selectedFile) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(this.selectedFile)
        fileReader.onload = async () => {
          this.category.avatar = fileReader.result
          await this.callCreateCategory()
        }
      } else {
        this.category.avatar = null
        await this.callCreateCategory()
      }
    },
    async updateCategory(id) {
      if(this.selectedFile) {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(this.selectedFile)
        fileReader.onload = async () => {
          this.category.avatar = fileReader.result
          await this.callUpdateCategory(id)
        }
      }
      else {
        this.category.avatar = null
        await this.callUpdateCategory(id)
      }
    },
    async callCreateCategory() {
      let response = await categoryService.createCategory(this.category)
      if (response) {
        this.categoryModal = false
        await this.getCategories()
      }
    },
    async callUpdateCategory(id) {
      let response = await categoryService.updateCategory(this.category, id)
      if (response) {
        this.categoryModal = false
        await this.getCategories()
      }
    },
    categoryImage(avatar) {
      return avatar !== null ?
          this.imageUrl + avatar :
          process.env.VUE_APP_API_PUBLIC_URL + 'images/placeholder.svg'
    }
  },

  created: async function () {
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    await this.getCategories()
  },

  watch: {
    categoryModal: function () {
      if (!this.categoryModal) {
        this.category = {
          organization_id: this.organization.id,
        }
      }
    },
  }

}
</script>

<style scoped>

</style>
