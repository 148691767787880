<template>
    <header class="center-align">
      <div id="logo" class="media" data-src="logo.png" src="./logo.png"></div>
      <span class="fw-bold h5">{{ organization.name }}</span><br>
      <span>{{ sale.store.name }} Branch</span><br>
      <span>{{ sale.store.address }}</span><br>
      <span>{{ sale.store.phone }}</span><br>
      <span>PIN NO: {{ organization.pin_no }}</span>
    </header>

    <br>
    <table class="bill-details">
      <tbody>
      <tr class="dash-line-top">
        <td>Sale#: <span>{{ sale.reference }}</span></td>
        <td>Source: <span class="text-uppercase">{{ sale.source }}</span></td>
      </tr>
      <tr class="dash-line-bottom">
        <td>Date/Time: <span>{{ formatDate(sale.created_at, true) }}</span></td>
      </tr>
      <tr><td><p>.</p></td></tr>
      <tr>
        <th class="center-align" colspan="2"><span class="receipt">Sale Receipt</span></th>
      </tr>
      </tbody>
    </table>

    <table class="items">
      <thead>
      <tr>
        <th class="heading name">Item/Sku</th>
        <th class="heading qty">Qty</th>
        <th class="heading rate">Price</th>
        <th class="heading amount">Amount</th>
      </tr>
      </thead>
    </table>

    <table v-for="product in products" class="items">
      <tr>
        <td class="price" colspan="4">{{ product.product.name }}</td>
      </tr>
      <tr>
        <td>{{ product.product.sku }}</td>
        <td>{{ product.quantity }}</td>
        <td class="price">{{ formatNumber(product.price, false) }}</td>
        <td class="price">{{ formatNumber(product.quantity * product.price, false) }} {{ product.product.tax_category.label }}</td>
      </tr>
    </table>

    <table class="items">
      <tbody>
      <tr>
        <td colspan="3" class="sum-up line">Items Count</td>
        <td class="line price">{{ countItems }}</td>
      </tr>
      <tr>
        <td colspan="3" class="sum-up">Sub-total</td>
        <td class="price">{{ formatNumber(sale.total - sale.tax, false) }}</td>
      </tr>
      <tr>
        <td colspan="3" class="sum-up">Tax</td>
        <td class="price">{{ formatNumber(sale.tax, false) }}</td>
      </tr>
      <tr>
        <td colspan="3" class="total text" style="font-weight: bolder; font-size: 18px; text-align: right">Total</td>
        <td class="total price" style="font-weight: bolder; font-size: 18px">{{ formatNumber(sale.total, false) }}</td>
      </tr>
      <tr>
        <td colspan="3" class="sum-up line">Paid</td>
        <td class="line price">{{ formatNumber(sale.paid, false) }}</td>
      </tr>
      <tr>
        <td colspan="3" class="sum-up line">Change</td>
        <td class="line price">{{ formatNumber(sale.paid - sale.total, false) }}</td>
      </tr>
      <tr>
        <th colspan="3" class="total"></th>
        <th class="total"></th>
      </tr>
      </tbody>
    </table>
    <section>
      <p>
        <span>Served by: {{ sale.user.first_name }}</span><br>
        <span>Paid by: CASH</span>
      </p>
      <p style="text-align:center">
        Thank you for shopping with us!
      </p><br>
    </section>
    <footer class="dash-line-top dash-line-bottom" style="text-align:center; font-size: 11px">
      <p>System By: Resley Tech Ltd</p>
      <p>info@resley.tech</p>
    </footer>
</template>

<script>

import formatNumber from "@/utils/formatNumber";
import formatDate from "@/utils/formatDate";

export default {
  name: "Receipt",
  props: {
    sale: {
      required: true,
      type: Object,
    },
    organization: {
      required: true,
      type: Object
    },
    products: {
      required: true,
      type: Array
    }
  },

  methods: {
    formatNumber,
    formatDate
  },

  computed: {
    countItems() {
      let total = 0
      for (let i = 0; i < this.products.length; i++) {
        total += this.products[i].quantity
      }
      return total
    }
  }
}
</script>

<style scoped>
@page {
  size: 2.8in 11in;
  margin-top: 0px;
  margin-left: -30px;
  margin-right: -30px;
}

table {
  width: 100%;
}

tr {
  width: 100%;

}

h1 {
  text-align: center;
  vertical-align: middle;
}

#logo {
  width: 60%;
  text-align: center;
  -webkit-align-content: center;
  align-content: center;
  padding: 5px;
  margin: 2px;
  display: block;
  margin: 0 auto;
}

header {
  width: 100%;
  text-align: center;
  -webkit-align-content: center;
  align-content: center;
  vertical-align: middle;
}

.items thead {
  text-align: center;
}

.center-align {
  text-align: center;
}

.bill-details td {
  font-size: 13px;
}

.receipt {
  font-size: medium;
}

.items .heading {
  font-size: 13px;
  text-transform: uppercase;
  border-top:1px solid black;
  margin-bottom: 4px;
  border-bottom: 1px solid black;
  vertical-align: middle;
}

.items thead tr th:first-child,
.items tbody tr td:first-child {
  width: 47%;
  min-width: 47%;
  max-width: 47%;
  word-break: break-all;
  text-align: left;
}

.items td {
  font-size: 14px;
  text-align: right;
  vertical-align: bottom;
}

.price::before {
  font-family: Arial;
  text-align: right;
}

.sum-up {
  text-align: right !important;
}
.total {
  font-size: 13px;
  border-top:1px dashed black !important;
  border-bottom:1px dashed black !important;
}
.total.text, .total.price {
  text-align: right;
}
.line {
  border-top:1px solid black !important;
}
.dash-line-top {
  font-size: 13px;
  border-top:1px dashed black !important;
}
.dash-line-bottom {
  font-size: 13px;
  border-bottom:1px dashed black !important;
}
.heading.rate {
  width: 20%;
}
.heading.amount {
  width: 25%;
}
.heading.qty {
  width: 5%
}
p {
  padding: 1px;
  margin: 0;
}
section, footer {
  font-size: 12px;
}
</style>
