<template>
  <div>
    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5 bg-white">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Edit Product
              </span>
            </div>
          </template>
          <div class="col-md-12">
            <form @submit.prevent="">
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="code" class="form-label fw-bold h5 mt-3">Product No:</label>
                  <input type="text" class="form-control form-control-lg" v-model="product.code" readonly>
                </div>
                <div class="form-group col-md-3">
                  <label for="sku" class="form-label fw-bold h5 mt-3">SKU:</label>
                  <input type="text" class="form-control form-control-lg" v-model="product.sku">
                </div>
                <div class="form-group col-md-6">
                  <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
                  <input type="text" class="form-control form-control-lg" v-model="product.name">
                </div>
                <div class="form-group col-md-3">
                  <label for="stock" class="form-label fw-bold h5 mt-3">Available Stock:</label>
                  <input type="number" class="form-control form-control-lg" v-model="product.stock">
                </div>
                <div class="form-group col-md-3">
                  <label for="reorder" class="form-label fw-bold h5 mt-3">Reorder Level:</label>
                  <input type="number" class="form-control form-control-lg" v-model="product.reorder">
                </div>
                <div class="form-group col-md-3">
                  <label for="buy" class="form-label fw-bold h5 mt-3">Buying Price:</label>
                  <input type="number" class="form-control form-control-lg" v-model="product.buy">
                </div>
                <div class="form-group col-md-3">
                  <label for="sell" class="form-label fw-bold h5 mt-3">Selling Price:</label>
                  <input type="number" class="form-control form-control-lg" v-model="product.sell">
                </div>
                <div class="form-group col-md-6">
                  <label for="description" class="form-label fw-bold h5 mt-3">Description: <span class="text-black-50 fst-italic h6">(optional)</span></label>
                  <input type="text" class="form-control form-control-lg" placeholder="Description" v-model="product.description">
                </div>
                <hr class="border-1 border-info border-opacity-75 mt-5" />
                <div class="form-group col-md-3">
                  <label for="category" class="form-label fw-bold h5 mt-3">Category:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="product.category_id">
                    <option value="" disabled selected>Select category</option>
                    <option v-for="category in categories" :key="category.id" :value="category.id">{{category.name}}</option>
                  </select>
                  <span class="float-end mt-1">
                    <button v-if="profile.role !== 'user'" type="button" class="btn btn-sm btn-outline-success" @click="addCategory">+ Add Category</button>
                  </span>
                </div>
                <div class="form-group col-md-3">
                  <label for="work_unit" class="form-label fw-bold h5 mt-3">Unit:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="product.unit_id">
                    <option value="" disabled selected>Select unit</option>
                    <option v-for="unit in units" :key="unit.id" :value="unit.id">{{unit.name}}</option>
                  </select>
                  <span class="float-end mt-1">
                    <button v-if="profile.role !== 'user'" type="button" class="btn btn-sm btn-outline-success" @click="addUnit">+ Add Work Unit</button>
                  </span>
                </div>
                <div class="form-group col-md-3">
                  <label for="work_unit" class="form-label fw-bold h5 mt-3">Tax Category:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="product.tax_category_id">
                    <option value="" disabled selected>Select tax category</option>
                    <option v-for="category in taxCategories" :key="category.id" :value="category.id">{{category.rate}}% - {{category.description}}</option>
                  </select>
                  <span class="float-end mt-1">
                    <button v-if="profile.role !== 'user'" type="button" class="btn btn-sm btn-outline-success" @click="addUnit">+ Add Tax Category</button>
                  </span>
                </div>
                <div class="form-group col-md-3">
                  <label for="supplier" class="form-label fw-bold h5 mt-3">Supplier:</label>
                  <select data="" type="text" class="form-select form-select-lg" v-model="product.supplier_id">
                    <option value="" disabled selected>Select supplier</option>
                    <option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">{{supplier.name}}</option>
                  </select>
                  <span class="float-end mt-1">
                    <button v-if="profile.role !== 'user'" type="button" class="btn btn-sm btn-outline-success" @click="addSupplier">+ Add Supplier</button>
                  </span>
                </div>
              </div>
            </form>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-4" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button v-if="profile.role !== 'user'" class="btn btn-primary text-uppercase" @click="submitForm">Submit</button>
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import productService from "@/api/productService";
import categoryService from "@/api/categoryService";
import unitService from "@/api/unitService";
import supplierService from "@/api/supplierService";
import router from "@/router";
import {decrypt} from "@/utils/crypto";
import taxCategoryService from "@/api/taxCategoryService";

export default {
  name: "EditProduct",
  data() {
    return {
      categories: [],
      units: [],
      suppliers: [],
      taxCategories: [],
      product: {},
      profile: {},
    }
  },
  methods: {
    submitForm: async function () {
      await productService.updateProduct(this.product, this.$route.params.id)
    },
    addCategory: function () {
      router.push({
        path: 'categories',
        query: { add: 'true' }
      })
    },
    addSupplier: function () {
      router.push({
        path: 'suppliers',
        query: { add: 'true' }
      })
    },
    addUnit: function () {
      router.push({
        path: 'work-units',
        query: { add: 'true' }
      })
    },
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.product = await productService.getProduct(this.$route.params.id)
    this.categories = await categoryService.getCategories()
    this.suppliers = await supplierService.getSuppliers()
    this.taxCategories = await taxCategoryService.getTaxCategories()
    this.units = await unitService.getUnits()
  },

}
</script>

<style scoped>

</style>
