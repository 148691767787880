<script>
import icons from './../../helpers/icons';
import authService from "@/api/authService";
import router from "@/router";
import { SidebarMenu } from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import {decrypt} from "@/utils/crypto";

export default {
  name: 'DefaultNavLayout',
  components: {SidebarMenu},

  data() {
    return {
      collapsedWidth: '65px',
      organization: {},
      store: {},
      profile: {},
      subscription: {
        package: {}
      },
      avatarUrl: process.env.VUE_APP_API_IMAGE_URL + 'user/',
      orgLogoUrl: process.env.VUE_APP_API_IMAGE_URL + 'organization/',
      icons,
      // State of sidebar
      sidebarClosed: false,
      windowWidth: '',
    }
  },
  methods: {
    async logout() {
      await authService.userLogout()
    },
    myProfile () {
      router.push('/profile')
    },
    switchStore() {
      router.push('/select-store')
    },
    linkActive(paths) {
      let routeTo = this.$route.path.split('/', 2)[1]
      return paths.includes(routeTo)
    },
    toggleSidebar() {
      this.sidebarClosed = !this.sidebarClosed
    },
    resizeToggle() {
      this.sidebarClosed = window.innerWidth < 1000;
      this.collapsedWidth = window.innerWidth < 600 ? '0px' : '65px';
    },
  },

  computed: {
    menu() {
      let superAdmin = this.profile.role !== 'super_admin'
      let primaryUser = this.profile.role !== 'primary'
      let bothAdmins = superAdmin && primaryUser
      let freeMode = this.subscription.package.name === 'Free'
      return [
        {
          hiddenOnCollapse: true,
          attributes: {
            style: 'margin: 15px 0 15px 90px !important;' +
                'cursor: pointer;' +
                'background-image: url(' + this.orgLogo + ');' +
                'background-size: contain;' +
                'background-color: white;' +
                'background-repeat: no-repeat;' +
                'border-radius: 10px;' +
                'width: 40px;' +
                'height: 50px;',
            onclick: () => router.push('/'),
          },
        },
        {
          title: 'Dashboard',
          href: '/dashboard',
          icon: 'bi bi-house',
          active: ['dashboard'],
        },
        {
          title: 'Organization',
          icon: 'bi bi-building',
          child: [
            {
              title: 'Organization Info',
              href: '/organization',
              icon: 'bi bi-building-check',
              class: 'ms-4',
            },
            {
              title: 'Stores',
              href: '/stores',
              icon: 'bi bi-shop',
              class: 'ms-4',
            },
          ],
        },
        {
          title: 'Products',
          icon: 'bi bi-list-check',
          isActive: (item) => this.linkActive(item.active),
          active: ['products', 'product', 'add-product', 'edit-product'],
          child: [
            {
              title: 'All Products',
              href: '/products',
              icon: 'bi bi-list',
              class: 'ms-4',
            },
            {
              title: 'Add Product',
              href: '/add-product',
              icon: 'bi bi-plus-lg',
              class: 'ms-4',
            },
            {
              title: 'Categories',
              href: '/categories',
              isActive: (item) => this.linkActive(item.active),
              active: ['categories', 'category'],
              icon: 'bi bi-diagram-3',
              class: 'ms-4',
            },
            {
              title: 'Units',
              href: '/units',
              icon: 'bi bi-ui-checks-grid',
              class: 'ms-4',
            },
            {
              title: 'Store Products',
              href: '/store-products',
              icon: 'bi bi-card-checklist',
              isActive: (item) => this.linkActive(item.active),
              active: ['store-products'],
              class: 'ms-4',
            },
          ]
        },
        {
          title: 'Suppliers',
          icon: 'bi bi-boxes',
          isActive: (item) => this.linkActive(item.active),
          active: ['suppliers', 'supplier', 'add-supplier', 'edit-supplier'],
          child: [
            {
              title: 'All Suppliers',
              href: '/suppliers',
              icon: 'bi bi-boxes',
              class: 'ms-4',
            },
            {
              title: 'Add Supplier',
              href: '/add-supplier',
              icon: 'bi bi-plus-lg',
              class: 'ms-4',
            },
          ]
        },
        {
          title: 'Purchase Orders',
          icon: 'bi bi-cart',
          child: [
            {
              title: 'All Store Orders',
              href: '/all-store-orders',
              isActive: (item) => this.linkActive(item.active),
              active: ['all-store-orders', 'store-order'],
              icon: 'bi bi-list-task',
              class: 'ms-4',
            },
            {
              title: 'All Supplier Orders',
              href: '/orders',
              isActive: (item) => this.linkActive(item.active),
              active: ['orders', 'order'],
              icon: 'bi bi-list-task',
              class: 'ms-4',
            },
            {
              title: 'Store Orders',
              href: '/store-orders',
              icon: 'bi bi-list-task',
              isActive: (item) => this.linkActive(item.active),
              active: ['store-orders', 'store-order'],
              class: 'ms-4',
            },
          ]
        },
        {
          title: 'Sales',
          icon: 'bi bi-receipt',
          child: [
            {
              title: 'All Sales',
              href: '/sales',
              icon: 'bi bi-receipt',
              isActive: (item) => this.linkActive(item.active),
              active: ['sales', 'sale'],
              class: 'ms-4',
            },
            {
              title: 'Store Sales',
              href: '/store-sales',
              icon: 'bi bi-receipt',
              isActive: (item) => this.linkActive(item.active),
              active: ['store-sales', 'sale'],
              class: 'ms-4',
            },
          ]
        },
        {
          title: 'Purchases',
          href: '/purchases',
          icon: 'bi bi-bag-check',
          isActive: (item) => this.linkActive(item.active),
          active: ['purchases', 'purchase'],
        },
        {
          title: 'Transfers',
          href: '/transfers',
          icon: 'bi bi-arrow-left-right',
          isActive: (item) => this.linkActive(item.active),
          active: ['transfers', 'transfer'],
        },
        {
          title: 'POS',
          href: '/pos',
          icon: 'bi bi-cart4',
          active: ['pos'],
        },
        {
          title: 'Transactions',
          href: '/transactions',
          icon: 'bi bi-cash-coin',
        },
        {
          title: 'Stock Take',
          href: '/stock-take',
          icon: 'bi bi-card-list',
          isActive: (item) => this.linkActive(item.active),
          active: ['stock-take', 'stock-take-details'],
        },
        {
          title: 'Store Analytics',
          href: '/store-analytics',
          icon: 'bi bi-bar-chart-line',
          isActive: (item) => this.linkActive(item.active),
          active: ['store-analytics'],
        },
        {
          title: 'Account',
          icon: 'bi bi-gear',
          hidden: bothAdmins,
          child: [
            {
              href: '/logs',
              title: 'System Logs',
              icon: 'bi bi-clock-history',
              class: 'ms-4',
            },
          ],
        },
        {
          title: 'Users',
          href: '/users',
          icon: 'bi bi-people',
          isActive: (item) => this.linkActive(item.active),
          active: ['users', 'user'],
          hidden: bothAdmins,
        },
        {
          title: 'All Organizations',
          href: '/organizations',
          icon: 'bi bi-buildings',
          active: ['organizations'],
          hidden: superAdmin,
        },
      ]
    },
    orgLogo() {
      return this.organization.logo !== null ?
          this.orgLogoUrl + this.organization.logo :
          'https://berilot.com/src/img/logos/logo.png'
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resizeToggle);
    })
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.resizeToggle);
  },

  created: async function () {
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
    this.resizeToggle()
  }
}
</script>

<template>
  <div class="main-container">
    <!-- Sidebar -->
    <sidebar-menu
        :menu="menu"
        hide-toggle
        style="background-color: #0d4072"
        :class="['sidebar', sidebarClosed?'closed':'']"
        :collapsed="sidebarClosed"
        :widthCollapsed="collapsedWidth"
    />

    <div :class="['app-container', {'sidebar-open':!sidebarClosed}]">
      <Navbar @sidebarToggle="toggleSidebar">
        <template v-slot:default="{toggleSidebar}">
          <div class="d-flex align-items-center w-100">
            <!--Hamburger menu-->
            <div @click="toggleSidebar" class="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" class="stroke-default" width="30" height="30"
                   viewBox="0 0 24 24" stroke-width="1.5" fill="none" stroke-linecap="round"
                   stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z"/>
                <line x1="4" y1="6" x2="20" y2="6"/>
                <line x1="4" y1="12" x2="20" y2="12"/>
                <line x1="4" y1="18" x2="20" y2="18"/>
              </svg>
            </div>
            <!--Search field-->
            <div class="ms-5 d-none d-sm-block" style="width:500px;">
              <h4>{{ organization.name }} - {{ store.name }}</h4>
            </div>
            <div class="ms-auto d-flex flex-row justify-content-end align-items-center">
              <!--Notification bell-->
              <div class="cursor-pointer position-relative ms-4">
                <Dropdown size="md" menu-classes="dropdown-menu-sm"
                          :popper-config="{placement:'bottom-end'}">
                  <template #button>
                    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-default" width="30"
                         height="30" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4CAF50"
                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z"/>
                      <path
                          d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"/>
                      <path d="M9 17v1a3 3 0 0 0 6 0v-1"/>
                    </svg>
                    <!--Notification badge-->
                    <Badge pill class="font-size-50 position-absolute" style="top:0;right: 0;">
                    </Badge>
                  </template>
                  <!-- Dropdown content with multiple orders-->
<!--                  <div class="d-flex flex-row p-2 align-content-center justify-content-center" v-for="{name, title} in [-->
<!--                        {-->
<!--                            name: 'notification name',-->
<!--                            title: 'notification title'-->
<!--                        },-->
<!--                    ]">-->
<!--                    <div class="d-flex flex-column justify-content-center me-3">-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <div class="text-success font-size-100 fw-bold">{{ title }}</div>-->
<!--                      <div class="color-gray-500 font-size-75">{{ name }}</div>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="text-center p-2 font-size-75">
                    <a href="#" class=" color-gray-500">Mark all as read</a>
                  </div>
                </Dropdown>
              </div>
              <!--User avatar with dropdown -->
              <div class="ms-5">
                <Dropdown class="">
                  <template #button>
                    <div
                        class="d-flex flex-row align-items-center justify-content-center cursor-pointer">
                      <div class="me-3">
                        <div class="fw-bold">{{ profile.first_name }}</div>
                        <div class="font-size-75 color-gray-600">{{ profile.role }}</div>
                      </div>
                      <img style="max-width: 30px;" class="rounded-circle"
                           :src="avatarUrl + profile.avatar" alt="avatar">
                    </div>
                  </template>
                  <!-- Dropdown content -->
                  <div class="dropdown-item d-flex align-items-center p-2" @click="myProfile">
                    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-default" width="20"
                         height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4CAF50"
                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z"/>
                      <path
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"/>
                      <circle cx="12" cy="12" r="3"/>
                    </svg>
                    <div class="ms-3 ">
                      My Profile
                    </div>
                  </div>
                  <div class="dropdown-item d-flex align-items-center p-2" @click="switchStore">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 9L9 9" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M15 15L3 15" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M18 12L20.913 9.08704V9.08704C20.961 9.03897 20.961 8.96103 20.913 8.91296V8.91296L18 6" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 18L3.08704 15.087V15.087C3.03897 15.039 3.03897 14.961 3.08704 14.913V14.913L6 12" stroke="#323232" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    <div class="ms-3 ">
                      Switch Store
                    </div>
                  </div>
                  <div class="dropdown-item d-flex align-items-center p-2" @click="logout">
                    <svg xmlns="http://www.w3.org/2000/svg" class="stroke-default" width="20"
                         height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#4CAF50"
                         fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z"/>
                      <path
                          d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"/>
                      <path d="M7 12h14l-3 -3m0 6l3 -3"/>
                    </svg>
                    <div class="ms-3">
                      Logout
                    </div>
                  </div>
                </Dropdown>
              </div>
            </div>
          </div>
        </template>
      </Navbar>
      <!-- Content with router view -->
      <div class="content-container container-fluid mt-4 mb-5">
        <div class="row">
          <div class="col">
            <router-view v-slot="{ Component }">
              <transition name="fade" mode="out-in">
                <keep-alive>
                  <component :is="Component" />
                </keep-alive>
              </transition>
            </router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-leave-to {
  opacity: 0;
}

.logo-text {
  background: linear-gradient(45deg, var(--bs-primary), var(--bs-secondary));
  background-clip: text;
  color: transparent;
}
.v-sidebar-menu .vsm--link_active {
  background-color: #2c78c1 !important;
}
.v-sidebar-menu .vsm--link_hover {
  background-color: #296eb1 !important;
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_active{
  box-shadow: 4px 0 0 0 #ffffff inset !important;
}
.v-sidebar-menu .vsm--link_level-2.vsm--link_active{
  box-shadow: 4px 0 0 0 #ffffff inset !important;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon{
  box-shadow: 1px 0 0 0 #ffffff inset !important;
}
</style>
