<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div v-if="!loading" class="text-center mb-4">
          <span class="h4 text-uppercase">Sale Details</span><br>
          <span class="h5 text-uppercase">{{ sale.store.code }}-{{ sale.store.name }}</span><br>
          <span class="h6">Created By: {{ sale.user.first_name }} {{ sale.user.last_name }}</span><br>
          <span class="h6">Created At: {{ formatDate(sale.created_at) }}</span><br>
          <span :class="`badge bg-${getSaleStatus(sale.status)}`">
            {{ sale.status }}
          </span><br>
          <span class="h5">Balance: {{ formatNumber(sale.total - totalTransactions) }}</span>
        </div>
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              Sale Products ({{saleProducts.length}})
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover text-nowrap">
              <thead>
              <tr>
                <th scope="col">SKU</th>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Quantity</th>
                <th scope="col">Price</th>
                <th scope="col">Tax</th>
                <th scope="col">Total</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="saleProduct in saleProducts" :key="saleProduct.id">
                <td>{{ saleProduct.product.sku }}</td>
                <td>{{ saleProduct.product.code }}</td>
                <td>{{ saleProduct.product.name }}</td>
                <td>{{ saleProduct.quantity }} {{ saleProduct.product.unit.name }}</td>
                <td>{{ formatNumber(saleProduct.price) }}</td>
                <td>{{ formatNumber(saleProduct.tax) }}</td>
                <td>{{ formatNumber(saleProduct.quantity * saleProduct.price) }}</td>
              </tr>
              <tr>
                <td colspan="5" class="fw-bold">Total:</td>
                <td class="fw-bold">{{ formatNumber(sale.tax) }}</td>
                <td class="fw-bold">{{ formatNumber(sale.total) }}</td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button class="btn btn-info text-uppercase" v-print="'#printMe'">Print</button>
          </div>
        </Card>

        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                Transactions ({{sale.transactions.length}})
                <span class="pb-5">
                  <button
                      v-if="sale.total - totalTransactions > 0"
                      class="btn btn-success btn-sm"
                      @click="transactionModal = true"
                  >+ Add Transaction</button>
                </span>
              </span>
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover text-nowrap">
              <thead>
              <tr>
                <th scope="col">Amount</th>
                <th scope="col">Method</th>
                <th scope="col">Reference</th>
                <th scope="col">Date</th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-for="transaction in sale.transactions" :key="transaction.id">
                <td>{{ formatNumber(transaction.amount) }}</td>
                <td>{{ transaction.method.toUpperCase() }}</td>
                <td>{{ transaction.reference }}</td>
                <td>{{ formatDate(transaction.created_at) }}</td>
              </tr>
              <tr>
                <td class="fw-bold">{{ formatNumber(totalTransactions) }}</td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Transaction Modal -->
    <Modal :open="transactionModal" @closed="transactionModal = false">
      <div class="modal-header">
        <h5 class="modal-title m-0">Add Transaction</h5>
        <button @click="transactionModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="method" class="form-label fw-bold h5 mt-3">Method:</label>
              <select data="" class="form-select form-select-lg" v-model="transaction.method">
                <option value="cash">Cash</option>
                <option value="mpesa">MPESA</option>
              </select>
            </div>
            <div class="form-group col-md-12">
              <label for="amount" class="form-label fw-bold h5 mt-3">Amount:</label>
              <input type="number" class="form-control form-control-lg" :min="sale.total - totalTransactions" placeholder="Amount" v-model="transaction.amount">
            </div>
            <div v-if="transaction.method === 'mpesa'" class="form-group col-md-12">
              <label for="reference" class="form-label fw-bold h5 mt-3">MPESA Reference:</label>
              <input type="text" class="form-control form-control-lg" placeholder="MPESA Reference" v-model="transaction.reference">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="transactionModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" @click="addTransaction">Submit</button>
      </div>
    </Modal>
    <!-- Add Transaction Modal End -->

    <!-- Receipt -->
    <div class="d-none">
      <div id="printMe">
        <Receipt :sale="sale" :products="saleProducts" :organization="organization"></Receipt>
      </div>
    </div>
    <!-- Receipt End -->

  </div>
</template>

<script>
import formatNumber from "@/utils/formatNumber";
import {decrypt} from "@/utils/crypto";
import saleService from "@/api/saleService";
import formatDate from "../../utils/formatDate";
import notify from "@/utils/notify";
import transactionService from "@/api/transactionService";
import Receipt from "@/components/receipt/Receipt.vue";
import print from 'vue3-print-nb';

export default {
  name: "SaleDetails",
  components: {Receipt},
  directives: {
    print
  },

  data(){
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    return {
      profile: {},
      store: {},
      organization: {},
      sale: {
        user: {},
        store: {},
        transactions: []
      },
      saleProducts: [],
      loading: true,
      transactionModal: false,
      transaction: {
        organization_id: this.organization.id,
        sale_id: this.$route.params.id,
        amount: 0,
        method: 'cash',
        reference: '',
      },
      subscription: {
        package: {}
      },
    }
  },

  methods:{
    formatDate,
    formatNumber,
    async getSaleDetails() {
      this.saleProducts = await saleService.getSaleDetails(this.$route.params.id)
      this.loading = false
    },
    getSaleStatus(status) {
      switch (status) {
        case 'complete':
          return 'success';
        case 'due':
          return 'primary';
      }
    },
    async addTransaction() {
      if (this.transaction.method === 'mpesa') {
        if (this.transaction.reference.length < 10) {
          notify.alert.info('Please enter valid MPESA reference', 2000)
          return;
        }
      }
      let response = await transactionService.createTransaction(this.transaction)
      if (response) {
        this.transactionModal = false
        await this.getSaleDetails()
        window.location.reload()
      }
    }
  },

  computed:{
    totalTransactions() {
      let total = 0;
      this.sale.transactions.forEach(transaction => {
        total += transaction.amount;
      })
      return total
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    this.organization = JSON.parse(decrypt(sessionStorage.getItem('organization')))
    this.sale = await saleService.getSale(this.$route.params.id)
    await this.getSaleDetails()
    this.transaction.amount = this.sale.total - this.totalTransactions
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
  },

  watch: {
    'transaction.reference': function () {
      this.transaction.reference = this.transaction.reference.toUpperCase()
    },
    'transaction.method': function () {
      if (this.transaction.method === 'cash') {
        this.transaction.reference = ''
      }
    }
  }

}
</script>

<style scoped>

</style>
