function formatDate(dateData, time = false) {
  let date = new Date(dateData)
  let month = date.toLocaleString('default', { month: 'long' })
  let day = ("0" + date.getDate()).slice(-2)
  let year = date.getFullYear()

  let timeString = time ? date.toLocaleTimeString('it-IT') : ''
  return day + ' ' + month + ', ' + year + ' ' + timeString
}

export default formatDate
