'use strict'
import api from '@/api/api'
import notify from "@/utils/notify"

export default {
    async getPOSProducts(id) {
        const data = {
            method: 'get',
            url: 'pos-products/' + id,
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
    async getLastSale() {
        const data = {
            method: 'get',
            url: 'lastSale',
        }

        const response = await api.api(data)
        if (response.status === 200) {
            return response.data
        }
    },
}
