<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="text-center mb-4">
          <span class="h4 text-uppercase">Store Purchase Order</span><br>
          <span class="h5 text-uppercase">{{ order.store.code }}-{{ order.store.name }}</span><br>
          <span class="h6">Created By: {{ order.user.first_name }} {{ order.user.last_name }}</span><br>
          <span class="h6">Created At: {{ formatDate(order.created_at) }}</span><br>
          <span :class="`badge bg-${getStockOrderStatus(order.status)}`">
            {{ order.status }}
          </span>
        </div>
        <Card v-if="order.status === 'pending'" body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              {{ store.name }} Products ({{productData.length}})
              <UInput v-model="dataTableSearch" size="md" placeholder="Search product" class="mb-0 w-25"/>
            </div>
          </template>
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading">
            <DataTable show-filter-buttons :data="productData" :headers="dataTableHeaders"
                       :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage">
              <template v-slot:default="{row}">
                <Cell
                    v-for="(cellData, key) in row"
                    :class="row[10] === true ? 'bg-info-light' : ''"
                    class="text-nowrap"
                    @click="populateAddProduct(row)"
                    :cell-classes="[
                        // Center
                        ['selected', 'status', 'reorder'].indexOf(key.toString().toLowerCase()) !== -1?'d-flex justify-content-center':'',
                    ]">
                  <Badge v-if="key.toString().toLowerCase() === 'reorder' || key === 8" pill :type="getPillByReorder(cellData)">
                    {{cellData}}
                  </Badge>
                  <Badge v-else-if="key.toString().toLowerCase() === 'status' || key === 9" pill :type="getPillByStatus(cellData)">
                    {{cellData}}
                  </Badge>
                  <span v-else-if="key === 0">{{cellData.split("-").pop()}}</span>
                  <span v-else-if="key === 10"></span>
                  <span v-else>{{cellData}}</span>
                </Cell>
              </template>
            </DataTable>
          </div>
          <div v-if="!loading" class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-between">
            <button class="btn btn-dark text-uppercase" @click="$router.go(-1)">Back</button>
            <div class="me-sm-4 mb-sm-0 mb-3">
              <p class="mb-2 small">Rows per page</p>
              <select data="" class="form-select" v-model="dataTablePerPage">
                <option v-for="item in [5, 10, 25, 50, 100]" :key="item" :value="item">{{item}}</option>
              </select>
            </div>
            <div>
              <Pagination v-model="dataTablePage" :pages="Math.ceil(dataTable.length/dataTablePerPage)">
                <template v-slot:next>
                  Next
                </template>
                <template v-slot:previous>
                  Prev
                </template>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              Purchase Order Products ({{orderProducts.length}})
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover table-sm text-nowrap">
              <thead>
              <tr>
                <th scope="col">SKU</th>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Qty</th>
                <th scope="col">Buying Price</th>
                <th scope="col">Total</th>
                <th scope="col">Comment</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-if="orderProducts.length === 0" class="text-center fst-italic text-muted">No products in purchase order.</tr>
              <tr v-for="orderProduct in orderProducts" :key="orderProduct.id" style="font-size: 14px">
                <td>{{ orderProduct.product.sku }}</td>
                <td>{{ orderProduct.product.code }}</td>
                <td>{{ orderProduct.product.name }}</td>
                <td>{{ orderProduct.quantity }} {{ orderProduct.product.unit.name }}</td>
                <td>{{ formatNumber(orderProduct.product.buy) }}</td>
                <td>{{ formatNumber(orderProduct.product.buy * orderProduct.quantity) }}</td>
                <td>{{ orderProduct.comment }}</td>
                <td v-if="order.status === 'pending'">
                  <button
                      class="btn btn-primary btn-sm me-2"
                      @click="addProductModal = true; addProductMode = 'edit'; populateOrderEdit(orderProduct)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                  <button
                      class="btn btn-danger btn-sm"
                      @click="deleteOrderProduct(orderProduct.id)"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
              <tr class="fw-bold">
                <td colspan="5">Total:</td>
                <td>{{ formatNumber(calculateTotals) }}</td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button
                v-if="order.status === 'pending'"
                class="btn btn-success text-uppercase"
                :class="orderProducts.length === 0 ? 'disabled' : ''"
                @click="confirmDialog(
                    'Complete Purchase Order',
                    submitOrder,
                    'Are you sure you want to complete this purchase order')">Submit Purchase Order
            </button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Order Modal -->
    <Modal :open="addProductModal" @closed="addProductModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="addProductMode === 'add'">Add Product to Purchase Order</h5>
        <h5 class="modal-title m-0" v-else>Edit Purchase Order Product</h5>
        <button @click="addProductModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" :value="selectedProduct.product.name" disabled>
            </div>
            <div class="form-group col-md-6">
              <label for="quantity" class="form-label fw-bold h5 mt-3">Quantity:</label>
              <input type="number" class="form-control form-control-lg" v-model="orderDetails.quantity">
            </div>
            <div class="form-group col-md-12">
              <label for="comment" class="form-label fw-bold h5 mt-3">Comment: <span class="text-black-50 fst-italic h6">(optional)</span></label>
              <input type="text" class="form-control form-control-lg" v-model="orderDetails.comment">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="addProductModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="addProductMode === 'add'" @click="addOrderProduct">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateOrderProduct(orderDetails.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Order Modal End -->

  </div>
</template>

<script>
import formatNumber from "@/utils/formatNumber";
import {decrypt} from "@/utils/crypto";
import storeOrderService from "@/api/storeOrderService";
import formatDate from "@/utils/formatDate";
import confirmDialog  from "@/utils/confirmDialog";
import storeStockService from "@/api/storeStockService";
import notify from "@/utils/notify";

export default {
  name: "OrderDetails",

  data(){
    return {
      profile: {},
      store: {},
      order: {
        store: {},
        user: {},
      },
      orderDetails: {
        store_order_id: this.$route.params.id,
        product_id: '',
        quantity: ''
      },
      selectedProduct: {
        product: {},
      },
      orderProducts: [],
      loading: true,
      addProductModal: false,
      addProductMode: 'add',
      dataTableHeaders: [
        '#',
        'SKU',
        'Code',
        'Name',
        'Buying Price',
        'Selling Price',
        'Stock',
        'Reorder',
        'Reorder Status',
        'Status',
        null
      ],
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 5,
      subscription: {
        package: {}
      },
    }
  },

  methods:{
    formatDate,
    formatNumber,
    confirmDialog,
    getPillByReorder(cellData) {
      switch (cellData) {
        case 'reorder':
          return 'danger';
        case 'good':
          return 'success';
      }
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'inactive':
          return 'danger';
        case 'active':
          return 'success';
      }
    },
    getStockOrderStatus(status) {
      switch (status) {
        case 'pending':
          return 'warning';
        case 'complete':
          return 'success';
        case 'submitted':
          return 'primary';
      }
    },
    async getStoreProducts() {
      this.dataTable = await storeStockService.getStoreStock(this.store.id)
      this.loading = false
    },
    async getOrderDetails() {
      this.orderProducts = await storeOrderService.getOrderDetails(this.$route.params.id)
    },
    populateAddProduct(product) {
      if (product[10] !== true) {
        this.addProductModal = true;
        this.selectedProduct = this.getSelectedProduct(product[0])
        this.orderDetails.product_id = this.selectedProduct.product.id
        this.orderDetails.price = this.selectedProduct.product.buy
      } else {
        notify.alert.info('Product already added', 2000)
      }
    },
    async addOrderProduct() {
      let response = await storeOrderService.createOrderDetails(this.orderDetails)
      if (response) {
        this.addProductModal = false
        await this.getOrderDetails()
      }
    },
    async updateOrderProduct() {
      let response = await storeOrderService.updateOrderDetails(this.orderDetails, this.orderDetails.id)
      if (response) {
        this.addProductModal = false
        await this.getOrderDetails()
      }
    },
    populateOrderEdit(order) {
      this.orderDetails = order
      this.selectedProduct.name = order.product.name
    },
    async deleteOrderProduct(id) {
      let response = await storeOrderService.deleteOrderDetails(id)
      if (response) {
        await this.getOrderDetails()
      }
    },
    async submitOrder() {
      await storeOrderService.changeOrderStatus({ status: 'submitted' },this.$route.params.id)
    },
    getSelectedProduct(id) {
      let data = this.dataTable.filter(
          item => item.product_id.indexOf(id) > -1
      )
      return data[0]
    },
    checkIncludedProduct(id) {
      let data = this.orderProducts.filter(
          item => item.product_id.indexOf(id) > -1
      )
      return data.length !== 0
    },
  },

  computed: {
    productData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].product_id,
          this.dataTable[i].product.sku,
          this.dataTable[i].product.code,
          this.dataTable[i].product.name,
          formatNumber(this.dataTable[i].product.buy),
          formatNumber(this.dataTable[i].price),
          this.dataTable[i].stock + ' ' + this.dataTable[i].product.unit.name,
          this.dataTable[i].reorder + ' ' + this.dataTable[i].product.unit.name,
          this.dataTable[i].reorder < this.dataTable[i].stock ? 'good' : 'reorder',
          this.dataTable[i].product.status,
          this.checkIncludedProduct(this.dataTable[i].product_id),
        ];
        data.push(fields);
      }
      return data;
    },
    calculateTotals() {
      let total = 0
      for (let i = 0; i < this.orderProducts.length; i++) {
        total += this.orderProducts[i].quantity * this.orderProducts[i].product.sell
      }
      return total
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.store = JSON.parse(decrypt(sessionStorage.getItem('store')))
    this.order = await storeOrderService.getOrder(this.$route.params.id)
    await this.getOrderDetails()
    await this.getStoreProducts()
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
  },

  watch: {
    addProductModal: function () {
      if (!this.addProductModal) {
        this.orderDetails = {
          store_order_id: this.$route.params.id
        }
      }
    }
  }

}
</script>

<style scoped>
td { cursor: pointer; }
</style>
