<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="text-center mb-4">
          <span class="h4 text-uppercase">Transfer Products to Store</span><br>
          <span class="h5 text-uppercase">Main Stock -> {{ transfer.store.code }}-{{ transfer.store.name }}</span><br>
          <span class="h6">Created By: {{ transfer.user.first_name }} {{ transfer.user.last_name }}</span><br>
          <span class="h6">Created At: {{ formatDate(transfer.created_at) }}</span><br>
          <span :class="`badge bg-${transfer.status === 'pending' ? 'warning' : 'success'}`">
            {{ transfer.status }}
          </span>
        </div>
        <Card v-if="transfer.status === 'pending'" body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              All Products ({{productData.length}})
              <UInput v-model="dataTableSearch" size="md" placeholder="Search product" class="mb-0 w-25"/>
            </div>
          </template>
          <div v-if="loading" class="text-center">
            <Spinner spinner-class="mb-1" />
          </div>
          <div v-if="!loading">
            <DataTable show-filter-buttons :data="productData" :headers="dataTableHeaders"
                       :search-keyword="dataTableSearch" :per-page="dataTablePerPage" :current-page="dataTablePage">
              <template v-slot:default="{row}">
                <Cell
                    v-for="(cellData, key) in row"
                    :class="row[11] === true ? 'bg-info-light' : ''"
                    class="text-nowrap"
                    @click="populateAddProduct(row)"
                    :cell-classes="[
                        // Center
                        ['selected', 'status', 'reorder'].indexOf(key.toString().toLowerCase()) !== -1?'d-flex justify-content-center':'',
                    ]">
                  <Badge v-if="key.toString().toLowerCase() === 'reorder' || key === 9" pill :type="getPillByReorder(cellData)">
                    {{cellData}}
                  </Badge>
                  <Badge v-else-if="key.toString().toLowerCase() === 'status' || key === 10" pill :type="getPillByStatus(cellData)">
                    {{cellData}}
                  </Badge>
                  <span v-else-if="key === 0">{{cellData.split("-").pop()}}</span>
                  <span v-else-if="key === 11"></span>
                  <span v-else>{{cellData}}</span>
                </Cell>
              </template>
            </DataTable>
          </div>
          <div v-if="!loading" class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-between">
            <button class="btn btn-dark text-uppercase" @click="$router.go(-1)">Back</button>
            <div class="me-sm-4 mb-sm-0 mb-3">
              <p class="mb-2 small">Rows per page</p>
              <select data="" class="form-select" v-model="dataTablePerPage">
                <option v-for="item in [5, 10, 25, 50, 100]" :key="item" :value="item">{{item}}</option>
              </select>
            </div>
            <div>
              <Pagination v-model="dataTablePage" :pages="Math.ceil(dataTable.length/dataTablePerPage)">
                <template v-slot:next>
                  Next
                </template>
                <template v-slot:previous>
                  Prev
                </template>
              </Pagination>
            </div>
          </div>
        </Card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <Card body-classes="p-0" class="mb-5">
          <template v-slot:header>
            <div class="d-flex align-items-center justify-content-between">
              Products to Transfer ({{transferProducts.length}})
            </div>
          </template>
          <div class="col-md-12 table-responsive">
            <table class="table table-hover table-sm text-nowrap">
              <thead>
              <tr>
                <th scope="col">SKU</th>
                <th scope="col">Code</th>
                <th scope="col">Name</th>
                <th scope="col">Qty</th>
                <th scope="col">Selling Price</th>
                <th scope="col">Total</th>
                <th scope="col">Added on</th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="!loading">
              <tr v-if="transferProducts.length === 0" class="text-center fst-italic text-muted">No products to transfer.</tr>
              <tr v-for="transferProduct in transferProducts" :key="transferProduct.id" style="font-size: 14px">
                <td>{{ transferProduct.product.sku }}</td>
                <td>{{ transferProduct.product.code }}</td>
                <td>{{ transferProduct.product.name }}</td>
                <td>{{ transferProduct.quantity }} {{ transferProduct.product.unit.name }}</td>
                <td>{{ formatNumber(transferProduct.product.sell) }}</td>
                <td>{{ formatNumber(transferProduct.product.sell * transferProduct.quantity) }}</td>
                <td>{{ formatDate(transferProduct.created_at) }}</td>
                <td v-if="transfer.status === 'pending'">
                  <button
                      class="btn btn-primary btn-sm me-2"
                      @click="addProductModal = true; addProductMode = 'edit'; populateTransferEdit(transferProduct)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                  <button
                      class="btn btn-danger btn-sm"
                      @click="deleteTransferProduct(transferProduct.id)"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
              <tr class="fw-bold">
                <td colspan="5">Total:</td>
                <td>{{ formatNumber(calculateTotals) }}</td>
              </tr>
              </tbody>
            </table>
            <div v-if="loading" class="text-center">
              <Spinner spinner-class="mb-1" />
            </div>
          </div>
          <hr class="border-1 border-info border-opacity-75 mt-3" />
          <div class="col-md-12 d-flex justify-content-center">
            <button class="btn btn-dark text-uppercase me-4" @click="$router.go(-1)">Back</button>
            <button
                v-if="transfer.status === 'pending'"
                class="btn btn-success text-uppercase"
                :class="transferProducts.length === 0 ? 'disabled' : ''"
                @click="confirmDialog('Complete Transfer', submitTransfer, 'Are you sure you want to complete this transfer')">Submit Transfer
            </button>
          </div>
        </Card>
      </div>
    </div>

    <!-- Add Transfer Modal -->
    <Modal :open="addProductModal" @closed="addProductModal = false" dialog-size="modal-lg">
      <div class="modal-header">
        <h5 class="modal-title m-0" v-if="addProductMode === 'add'">Add Product to Transfer</h5>
        <h5 class="modal-title m-0" v-else>Edit Transfer Product</h5>
        <button @click="addProductModal = false" type="button" class="btn-close outline-none" data-dismiss="modal" aria-label="Close">
        </button>
      </div>
      <div class="modal-body">
        <form @submit.prevent="">
          <div class="row">
            <div class="form-group col-md-12">
              <label for="name" class="form-label fw-bold h5 mt-3">Name:</label>
              <input type="text" class="form-control form-control-lg" :value="selectedProduct[3]" disabled>
            </div>
            <div class="form-group col-md-12">
              <label for="quantity" class="form-label fw-bold h5 mt-3">Quantity:</label>
              <input type="number" class="form-control form-control-lg" autofocus="autofocus" v-model="transferDetails.quantity">
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer border-1 border-info border-opacity-75 mt-3">
        <button type="button" class="btn btn-dark text-uppercase me-3" data-dismiss="modal" @click="addProductModal = false">Cancel</button>
        <button type="button" class="btn btn-primary text-uppercase" v-if="addProductMode === 'add'" @click="addTransferProduct">Submit</button>
        <button type="button" class="btn btn-primary text-uppercase" v-else @click="updateTransferProduct(transferDetails.id)">Update</button>
      </div>
    </Modal>
    <!-- Add Transfer Modal End -->

  </div>
</template>

<script>
import productService from "@/api/productService";
import formatNumber from "@/utils/formatNumber";
import {decrypt} from "@/utils/crypto";
import transferService from "@/api/transferService";
import formatDate from "@/utils/formatDate";
import notify from "@/utils/notify";
import confirmDialog  from "@/utils/confirmDialog";

export default {
  name: "TransferDetails",

  data(){
    return {
      profile: {},
      transfer: {
        store: {},
        user: {},
      },
      transferDetails: {
        transfer_id: this.$route.params.id,
        product_id: '',
        quantity: ''
      },
      selectedProduct: [],
      transferProducts: [],
      loading: true,
      addProductModal: false,
      addProductMode: 'add',
      dataTableHeaders: [
        '#',
        'SKU',
        'Code',
        'Name',
        'Buying Price',
        'Selling Price',
        'Stock',
        'Reorder',
        'Unit',
        'Reorder Status',
        'Status',
        null
      ],
      dataTable: [],
      dataTableSearch: '',
      dataTablePage: 1,
      dataTablePerPage: 5,
      subscription: {
        package: {}
      },
    }
  },

  methods:{
    formatDate,
    formatNumber,
    confirmDialog,
    getPillByReorder(cellData) {
      switch (cellData) {
        case 'reorder':
          return 'danger';
        case 'good':
          return 'success';
      }
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case 'inactive':
          return 'danger';
        case 'active':
          return 'success';
      }
    },
    async getProducts() {
      this.dataTable = await productService.getProducts()
      this.loading = false
    },
    async getTransferDetails() {
      this.transferProducts = await transferService.getTransferDetails(this.$route.params.id)
    },
    populateAddProduct(product) {
      if (product[11] !== true) {
        this.addProductModal = true;
        this.selectedProduct = product
        this.transferDetails.product_id = product[0]
        this.transferDetails.quantity = product[6]
      } else {
        notify.alert.info('Product already added', 2000)
      }
    },
    async addTransferProduct() {
      if (!this.checkStockQuantity(this.transferDetails.product_id)) return;
      let response = await transferService.createTransferDetails(this.transferDetails)
      if (response) {
        this.addProductModal = false
        await this.getTransferDetails()
      }
    },
    async updateTransferProduct() {
      if (!this.checkStockQuantity(this.transferDetails.product_id)) return;
      let response = await transferService.updateTransferDetails(this.transferDetails, this.transferDetails.id)
      if (response) {
        this.addProductModal = false
        await this.getTransferDetails()
      }
    },
    populateTransferEdit(transfer) {
      this.transferDetails = transfer
      this.selectedProduct[3] = transfer.product.name
    },
    async deleteTransferProduct(id) {
      let response = await transferService.deleteTransferDetails(id)
      if (response) {
        await this.getTransferDetails()
      }
    },
    checkStockQuantity(id) {
      let data = this.dataTable.filter(
          item => item.id.indexOf(id) > -1
      )
      if (this.transferDetails.quantity > data[0].stock) {
        notify.alert.error('Insufficient stock quantity', 3000)
        return false
      } else {
        return true
      }
    },
    async submitTransfer() {
      await transferService.approveTransfer(this.$route.params.id)
    },
    checkIncludedProduct(id) {
      let data = this.transferProducts.filter(
          item => item.product_id.indexOf(id) > -1
      )
      return data.length !== 0
    },
  },

  computed: {
    productData(){
      let data = [];
      for (let i = 0; i < this.dataTable.length; i++) {
        let fields = [
          this.dataTable[i].id,
          this.dataTable[i].sku,
          this.dataTable[i].code,
          this.dataTable[i].name,
          formatNumber(this.dataTable[i].buy),
          formatNumber(this.dataTable[i].sell),
          this.dataTable[i].stock,
          this.dataTable[i].reorder,
          this.dataTable[i].unit.name,
          this.dataTable[i].reorder < this.dataTable[i].stock ? 'good' : 'reorder',
          this.dataTable[i].status,
          this.checkIncludedProduct(this.dataTable[i].id),
        ];
        data.push(fields);
      }
      return data;
    },
    calculateTotals() {
      let total = 0
      for (let i = 0; i < this.transferProducts.length; i++) {
        total += this.transferProducts[i].quantity * this.transferProducts[i].product.sell
      }
      return total
    }
  },

  created: async function () {
    this.profile = JSON.parse(decrypt(sessionStorage.getItem('profile')))
    this.transfer = await transferService.getTransfer(this.$route.params.id)
    await this.getTransferDetails()
    await this.getProducts()
    this.subscription = JSON.parse(decrypt(sessionStorage.getItem('subscription')))
  },

  watch: {
    addProductModal: function () {
      if (!this.addProductModal) {
        this.selectedProduct = []
        this.transferDetails = {
          transfer_id: this.$route.params.id
        }
      }
    }
  }

}
</script>

<style scoped>
td { cursor: pointer; }
</style>
